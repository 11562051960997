import Vue from 'vue';
import AdminStavke from './App.vue';
import common from './js/common.js';
//import $ from 'jquery';

require('@/assets/Site.css')
require('@/assets/magnific-popup.css')
//require('@/js/jquery-3.3.1.min.js')
//require('@/js/jquery.magnific-popup.js')

Vue.config.productionTip = false

var vm = new Vue({
  render: h => h(AdminStavke),
methods: {
  
},
  created: function(){
    //console.log('created AdminStavke');
   
  },
  mounted: function() {
    //console.log('mounted');
   common.init();
  // $('[vp-cloak]').show();
  //  $('.sadrzaj .animator').animate({ marginLeft: "0px", opacity: "1" }, { duration: 500, complete: function () { } });
  }
})
vm.$mount('#app')