<template>
  <div id="app">
    <div v-if="!submitted">
    <AdminLogovanje @updateNaslovna="submit" />
    </div>
    <div v-else>
        <h1>Ставке за слање</h1>
    <form>
       <p align="right">
          <a href="../stavka.html">Додај</a>
      </p>
    <table class="table" style="width:100%">
        <tr>
             <th align="left">
                Наслов
            </th>
            <th align="cente">
                Група
            </th>
            <th align="cente">
               Послато
            </th>
            <th></th>
        </tr>

        <tr v-for="ot in sesije" :key="ot.ID">
            <td>
                {{ot.Subject}} 
            </td>
            <td align="left">
               {{ot.NaziveGrupe}}
            </td>
            <td align="left">
               {{ot.DatumSlanja}}
            </td>
            <td align="right" style="width:10%;white-space: nowrap;">
                  <a v-bind:href="'../stavka.html?id=' + ot.ID" >Измени</a> |
                <a href="javascript:void(null);"   @click="deleteStavku(ot.ID)">Обриши</a>
            </td>
        </tr>
    </table>
    </form>
  </div>
  </div>
</template>

<script>
import AdminLogovanje from './components/AdminLogovanje.vue';
import axios from 'axios';
import $ from 'jquery';
import appConfig from './js/configuration.js';
import common from './js/common.js';

export default {
  name: 'AdminStavke',
  data: function(){
    return {
      submitted: false,
      sesije: []
    }
  },
  
  components: {
    AdminLogovanje,
    //HelloWorld
  },  
   methods: {
        process: function(){
         var _this = this;
          common.checkAdminSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              if(!_this.submitted) {
                $('#admin_logovanje').show();
              }
              else{
                _this.fetch();
              }
          });
      },
      fetch: function() {
        var _this = this;
        var userToken = localStorage.getItem('adminToken');
          if(userToken != null){
           
             var postData = new FormData();
            postData.append('token',userToken);
            axios.post(appConfig.backendUrl + 'adminStavke.php',postData).then(function(response){
                   _this.sesije = response.data;                                  
              });
          }

      },
        submit: function(){
          this.submitted = true;
          //console.log(this.submitted);
          this.process();
          //console.log('submit');
        },
     deleteStavku: function(id){
          //var _this = this;
          var userToken = localStorage.getItem('adminToken');
          if(userToken != null){
            if(confirm('Да ли сте сигурни!?')){
            var postData = new FormData();
              postData.append('token',userToken);
              postData.append('id',id);
              axios.post(appConfig.backendUrl + 'adminBrisiStavku.php',postData).then(function(response){
                    //console.log(response.data);
                    if(response.data.result === true){
                      document.location.reload();
                    }
                }); 
            }
          }
      },
    },
    mounted: function(){
      //console.log("created 1");
      this.process();
    }
  }
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #2c3e50;
  margin-top: 60px; */
}
</style>
